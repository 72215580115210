import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            bryansk
          }
        }
      }
    `
  )

  const metaDescription = "Проститутки Брянска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Брянска готовы ко встрече в приватной обстановке." || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://vipdosug.org/prostitutki-bryanska/"/>
      <link rel="alternate" href="https://vipdosug.org/prostitutki-bryanska/" hreflang="ru"/>
      <link rel="amphtml" href="https://vipdosug.org/prostitutki-bryanska/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	  <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	  <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="vipdosug.org"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.bryansk}/>
      <meta property="og:url" content="https://vipdosug.org/prostitutki-bryanska/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.bryansk}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.bryansk}/>
	  <meta itemprop="description" content={metaDescription}/>
	  <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	  <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://vipdosug.org/vipdosug.atom" title="Проститутки России, лучшие индивидуалки | VipDosug.org"/>
      <script type="application/ld+json">
  {`
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": "https://vipdosug.org/",
    "name": "VipDosug.Org",
    "sameAs": [
        "https://www.youtube.com/@dosugcz8671",
        "https://flipboard.com/@dosug",
        "https://vk.com/dosug.cz_ru",
        "https://t.me/DosugOfficialle",
        "https://twitter.com/DosugczOfficial",
        "https://www.facebook.com/dosugru.net",
        "https://ru.pinterest.com/dosugru/"
    ]
}
  `}
</script>
<script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org",
    "author": {
        "@type": "WebPage",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/"
    },
    "@type": "Article",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://vipdosug.org/prostitutki-bryanska/"
    },
    "headline": "Проститутки Брянска, лучшие индивидуалки | VipDosug.org",
    "description": "Проститутки Брянска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Брянска готовы ко встрече в приватной обстановке.",
    "articleBody": "Гуляя по городу Брянску, вы сами не заметите, как вдруг станете томным романтиком, а в вашу голову полезут интересные желания. В частности, вы ощутите как низ живота будет медленно и стремительно наполняться томительным желанием любви. И это все не случайно. Потому что только в Брянске есть потрясающие проститутки, которые могут исполнить любые ваши эротические мечты! Они обладают навыками совершенно различных видов сексуальных развлечений. Проституки Брянска знают в идеале, как завести даже самого безнадежного импотента. Они могут ублажить вас потрясающим оральным сексом, анальным или классическим, могут позволить поиграть собой в ролевые игры, научат вас использовать и получать удовольствие от секс-игрушек. Никогда еще не пробовали такого? Время настало - и индивидуалки Брянска Вам докажут это! От них еще не один клиент не уходил недовольным, поэтому хватит мучиться в сомнениях, поднимайте трубку и набирайте номер.... Через некоторое количество времени проститутка Брянска будет уже ждать вас к себе или стоять на вашем пороге, готовая к сексуальным утехам!",
    "image": [
        "https://vipdosug.org/images/og-bryansk.png"
    ],
   
    "articleSection": "Развлечения",
    "publisher": {
        "@type": "Organization",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/",
        "logo": {
            "@type": "ImageObject",
            "url": "https://vipdosug.org/images/amp.png",
            "width": 264,
            "height": 59
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo
